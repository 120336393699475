import {makeStyles} from '@mui/styles'
import {Paper, Theme, Typography, Button} from '@mui/material'
import Form from '@rjsf/core'
import {useRecoilState} from 'recoil'

import {
  schema,
  widgets,
  ArrayFieldTemplate,
  defaultFormData,
} from './form-components'
import {modelDataState} from '../../atoms'
import {Box} from '@mui/system'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '& .control-label': {
      display: 'none',
    },
    '& .field-description': {
      display: 'none',
    },
    '& .form-group': {
      width: '100%',
    },
    '& .field-object': {
      border: 'none',
      // paddingLeft: theme.spacing(3),
      // paddingRight: theme.spacing(3),
    },
    '& .btn-info': {
      display: 'none',
    },
    '& #root': {
      border: 'none',
      margin: 0,
    },
  },
}))

export function TemplateForm() {
  const classes = useStyles()
  const [data, setData] = useRecoilState(modelDataState)

  //@ts-ignore - Should come back and create a type for the formData here.
  const updateData = ({formData}, e) => {
    setData(formData)
  }
  return (
    <Paper sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h5' sx={{mt: 1, ml: 1}}>
          Create Model
        </Typography>
        <Button
          onClick={() => setData(defaultFormData)}
          variant='outlined'
          sx={{mt: 1, mr: 1}}
        >
          Clear All
        </Button>
      </Box>
      <Form
        className={classes.form}
        schema={schema}
        widgets={widgets}
        ArrayFieldTemplate={ArrayFieldTemplate}
        formData={data}
        onChange={updateData}
        // liveValidate  // turns on validation for form
      />
    </Paper>
  )
}
