import cs from 'classnames'
import {makeStyles} from '@mui/styles'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Link,
  Theme,
} from '@mui/material'
import {Apps as AppsIcon} from '@mui/icons-material'

import imageURL from '../../images/powerbitips.svg'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  grow: {
    flexGrow: 1,
  },
  header: {
    fontWeight: 200,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    '&:hover, &.Mui-focusVisible': {color: theme.palette.secondary.dark},
  },
  outsideContainer: {
    width: '130px',
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    // position: 'absolute',
    // left: '16px',
    // top: '9px',
    height: '30px',
    // [theme.breakpoints.up('sm')]: {},
    // [theme.breakpoints.down('sm')]: {
    //   // height: '20px',
    // },
  },
}))

export function MainAppBar(props: MainAppBarProps) {
  const classes = useStyles()
  const {toggleOpen} = props
  return (
    <div>
      <AppBar color='primary' position='static'>
        <Toolbar variant='dense' className={classes.root}>
          <div className={classes.outsideContainer}>
            <IconButton
              edge='start'
              className={cs(classes.menuButton)}
              color='inherit'
              aria-label='menu'
              onClick={toggleOpen}
            >
              <AppsIcon />
            </IconButton>
            <Link href='https://powerbi.tips' className={classes.logoImage}>
              <img
                src={`${imageURL}`}
                className={classes.logoImage}
                alt='PowerBI.Tips'
              />
            </Link>
          </div>
          <Typography
            variant='h5'
            color='inherit'
            align='center'
            className={classes.header}
          >
            Model.JSON Builder
          </Typography>
          <div className={classes.outsideContainer}>
            <Button
              variant='contained'
              size='small'
              color='secondary'
              style={{opacity: '0'}}
              disabled
            >
              Sign In
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export type MainAppBarProps = {
  toggleOpen: () => void
}
