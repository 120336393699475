import {JSONSchema7} from 'json-schema'

export const schema: JSONSchema7 = {
  type: 'object',
  required: ['name', 'version'],
  properties: {
    name: {
      type: 'string',
      title: 'Model Name',
      default: '',
    },
    description: {
      type: 'string',
      title: 'Description',
    },
    version: {
      type: 'string',
      title: 'Version',
    },
    culture: {
      type: 'string',
      title: 'Culture',
      default: 'en',
      anyOf: [
        {type: 'string', title: 'English', enum: ['en']},
        {type: 'string', title: 'English (United States)', enum: ['en-US']},
        {type: 'string', title: 'English (Great Britain)', enum: ['en-GB']},
        {type: 'string', title: 'French', enum: ['fr']},
        {type: 'string', title: 'German', enum: ['de']},
        {type: 'string', title: 'Polish', enum: ['pl']},
        {type: 'string', title: 'Dutch', enum: ['nl']},
        {type: 'string', title: 'Finnish', enum: ['fi']},
        {type: 'string', title: 'Swedish', enum: ['sv']},
        {type: 'string', title: 'Italian', enum: ['it']},
        {type: 'string', title: 'Spanish (Spain)', enum: ['es']},
        {type: 'string', title: 'Portuguese (Portugal)', enum: ['pt']},
        {type: 'string', title: 'Russian', enum: ['ru']},
        {type: 'string', title: 'Portuguese (Brazil)', enum: ['pt-BR']},
        {type: 'string', title: 'Spanish (Mexico)', enum: ['es-MX']},
        {type: 'string', title: 'Chinese (PRC)', enum: ['zh-CN']},
        {type: 'string', title: 'Chinese (Taiwan)', enum: ['zh-TW']},
        {type: 'string', title: 'Japanese', enum: ['ja']},
        {type: 'string', title: 'Korean', enum: ['ko']},
      ],
    },

    annotations: {
      title: 'Annotations',
      type: 'array',

      items: {
        type: 'object',
        properties: {
          name: {type: 'string', title: 'Name'},
          value: {type: 'string', title: 'Value'},
        },
        required: ['name', 'value'],
      },
    },

    entities: {
      title: 'Entities',
      type: 'array',
      items: {
        type: 'object',
        properties: {
          $type: {
            type: 'string',
            enum: ['Local', 'Reference'],
            default: 'Local',
          },
        },
        required: ['$type'],
        dependencies: {
          $type: {
            oneOf: [
              {
                properties: {
                  //'No' selected
                  $type: {
                    enum: ['Reference'],
                  },
                  name: {
                    type: 'string',
                    title: 'Name',
                  },
                  description: {
                    type: 'string',
                    title: 'Description',
                  },
                  source: {
                    type: 'string',
                    title: 'Source',
                  },
                  modelID: {
                    type: 'number',
                    title: 'Model ID',
                  },
                  annotations: {
                    title: 'Annotations',
                    type: 'array',
                    items: {
                      type: 'object',
                      properties: {
                        name: {type: 'string', title: 'Name'},
                        value: {type: 'string', title: 'Value'},
                      },
                      required: ['name', 'value'],
                    },
                  },
                },
                required: ['$type', 'name', 'source', 'modelID'],
              },
              {
                properties: {
                  //'Yes: One' selected
                  $type: {
                    enum: ['Local'],
                  },
                  name: {
                    type: 'string',
                    title: 'Name',
                  },
                  description: {
                    type: 'string',
                    title: 'Description',
                  },
                  schema: {
                    title: 'Schemas',
                    type: 'array',
                    items: {
                      type: 'object',
                      properties: {
                        schemaURI: {type: 'string', title: 'Schema URI'},
                      },
                      required: ['schemaURI'],
                    },
                  },
                  attributes: {
                    title: 'Atributes',
                    type: 'array',
                    items: {
                      type: 'object',
                      properties: {
                        name: {type: 'string', title: 'Name'},
                        dataType: {type: 'string', title: 'Data Type'},
                      },
                      required: ['name', 'dataType'],
                    },
                  },
                  partitions: {
                    title: 'Partitions',
                    type: 'array',
                    items: {
                      type: 'object',
                      properties: {
                        name: {type: 'string', title: 'Name'},
                        location: {type: 'string', title: 'Location'},
                      },
                      required: ['name', 'location'],
                    },
                  },
                  annotations: {
                    title: 'Annotations',
                    type: 'array',
                    items: {
                      type: 'object',
                      properties: {
                        name: {type: 'string', title: 'Name'},
                        value: {type: 'string', title: 'Value'},
                      },
                      required: ['name', 'value'],
                    },
                  },
                },
              },
            ],
          },
        },
      },
    },
    referenceModels: {
      title: 'Reference Models',
      type: 'array',

      items: {
        type: 'object',
        properties: {
          id: {type: 'string', title: 'ID'},
          value: {type: 'string', title: 'Location'},
        },
        required: ['id', 'value'],
      },
    },
  },
}

export const defaultFormData = {
  name: '',
  description: '',
  version: '',
  culture: 'en-US',
  annotations: [],
  entities: [],
  referenceModels: [],
}
