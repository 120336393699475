/* eslint-disable array-callback-return */
import {useState} from 'react'
import {Theme, Toolbar} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Route, Switch} from 'react-router-dom'

import {MainAppBar} from './main-app-bar'
import {MainPage} from '../../pages'
import {ResponsiveDrawer} from './responsive-drawer'
import {PbiTipsToolsLinks, PbiTipsSocialLinks} from './pbi-tips-tools-links'
import {Footer} from './footer'

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    flexGrow: 1,
    height: '48px',
    width: '100%',
    position: 'fixed',
    borderRadius: '0px',
    zIndex: 999,
  },
  content: {
    height: '100%',
    display: 'block',
  },
}))

export function Layout() {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(o => !o)
  return (
    <>
      <div>
        <MainAppBar toggleOpen={toggleOpen} />
        <Toolbar />
        <ResponsiveDrawer toggleOpen={toggleOpen} isOpen={isOpen}>
          <PbiTipsToolsLinks />
          <PbiTipsSocialLinks />
        </ResponsiveDrawer>
        {/* <TabBar /> */}
      </div>
      <div className={classes.content}>
        <Content />
        {/* Need to add this to add padding for the footer bar*/}
        <Toolbar disableGutters />
      </div>
      <Footer />
    </>
  )
}

function Content() {
  return (
    <Switch>
      <Route exact path='/' component={MainPage} />
    </Switch>
  )
}
