import * as React from 'react'
import {Drawer, Hidden, Theme} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'
import logo from '../../images/logo.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      zIndex: 8,
    },

    // Necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      padding: theme.spacing(2.5),
      zIndex: 8,
    },
    drawerContents: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    drawerLinks: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },

    drawerHeader: {
      margin: theme.spacing(2),
      mariginBottom: theme.spacing(1),
    },
    imageIcon: {
      height: theme.spacing(5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    iconRoot: {
      textAlign: 'center',
    },
  })
)

export function ResponsiveDrawer(props: ResponsiveDrawerProps) {
  const {children} = props
  const classes = useStyles()

  const drawerContents = (
    <div className={classes.drawerContents}>
      <img className={classes.imageIcon} src={logo} alt='' />

      <div className={classes.drawerLinks}>{children}</div>
    </div>
  )

  return (
    <nav className={classes.drawer} aria-label='mailbox folders'>
      <MobileDrawer {...props}>{drawerContents}</MobileDrawer>
      <DesktopDrawer {...props}>{drawerContents}</DesktopDrawer>
    </nav>
  )
}

export type ResponsiveDrawerProps = {
  children: React.ReactNode
  isOpen: boolean
  toggleOpen: () => void
}

function MobileDrawer(props: ResponsiveDrawerProps) {
  const {children, isOpen, toggleOpen} = props
  const classes = useStyles()

  return (
    // The implementation can be swapped with js to avoid SEO duplication of links.
    <Hidden smUp implementation='css'>
      <Drawer
        variant='temporary'
        anchor='left'
        open={isOpen}
        onClose={toggleOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {children}
      </Drawer>
    </Hidden>
  )
}

function DesktopDrawer(props: ResponsiveDrawerProps) {
  const {children, isOpen, toggleOpen} = props
  const classes = useStyles()

  return (
    <Hidden xsDown implementation='css'>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant='temporary'
        open={isOpen}
        onClose={toggleOpen}
      >
        {children}
      </Drawer>
    </Hidden>
  )
}
