import {Theme, Typography, Link} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Box} from '@mui/system'
import {StackedAdContainer} from '../components'

import {TemplateForm} from '../components/template-form'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',

    height: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  contentCol: {
    [theme.breakpoints.up('sm')]: {
      flex: 6,
      margin: `0 ${theme.spacing(2)}`,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: '100%',
    },
  },
  secondContentCol: {
    [theme.breakpoints.up('sm')]: {
      flex: 4,
      margin: `0 ${theme.spacing(2)}`,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: '100%',
    },
  },
  fixedContainer: {
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
    },

    width: 'calc(40% - 40px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '35px',
  },
  description: {
    paddingBottom: '16px!important',
  },
  link: {
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
}))
export function MainPage() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.contentCol}>
        <Typography variant='h4' className={classes.title}>
          Data Flows Model Builder
        </Typography>
        <Typography className={classes.description}>
          Build a model schema for custom data within Data Flows. For the formal
          documentation on models click{' '}
          <Link
            href='https://docs.microsoft.com/en-us/common-data-model/model-json'
            className={classes.link}
          >
            here
          </Link>
        </Typography>

        <TemplateForm />
      </div>
      <div className={classes.secondContentCol}>
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            width: 'calc(40% - 40px)',
          }}
        >
          <StackedAdContainer />
        </Box>
      </div>
    </div>
  )
}
